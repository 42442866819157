import Card from 'components/card';

const OfferCard = (props: {
    image: string;
    title: string;
    author: string;
    bidders: string[];
    download?: string;
    price: string | number;
    weeklyQuantity?: string | number;
    extra?: string;
    status?: string;
    buttonDisabled?: boolean;
    isKYCVerified?: boolean;
    isValidated?: boolean;
    isArchived?: boolean;
    onClick?: () => void;
}) => {
    const {
        title,
        author,
        image,
        extra,
        status,
        isArchived,
        weeklyQuantity,
        onClick,
    } = props;
    return (
        <Card
            extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] border-2 shadow-xl bg-white ${extra} hover:cursor-pointer hover:shadow-xl`}
            onClick={onClick}
        >
            <div className="h-full w-full">
                <div className="relative w-full">
                    <img
                        src={image}
                        className="mb-3 max-h-[130px] w-full rounded-xl 3xl:h-full 3xl:w-full"
                        alt=""
                    />
                </div>

                <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
                    <div className="mb-2">
                        <p className="text-lg font-bold text-navy-700 dark:text-white">
                            {' '}
                            {title}{' '}
                        </p>
                        <p className="text-sm font-medium text-gray-600 md:mt-2">
                            {author}
                        </p>
                        {status === 'running' && (
                            <p className="mt-2 font-bold text-leadrs">
                                Mission en cours
                            </p>
                        )}
                        {isArchived && (
                            <p className="mt-2 text-sm font-bold text-leadrs">
                                Mission terminée
                            </p>
                        )}
                    </div>
                </div>

                <div className="flex items-center justify-between md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col 2xl:items-start 3xl:flex-row 3xl:items-center 3xl:justify-between">
                    <div className="flex">
                        <p className="mb-2 text-sm font-bold text-leadrs dark:text-white">
                            {weeklyQuantity} leads
                        </p>
                    </div>
                    <button
                        className="linear mx-auto rounded-[20px] bg-leadrs px-4 py-2 text-sm font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                        onClick={onClick}
                    >
                        Visualiser
                    </button>
                </div>
            </div>
        </Card>
    );
};

export default OfferCard;
