import axios from 'axios';
import Card from 'components/card';
import { useState } from 'react';
import { CONFIG } from 'variables/config';

const Invite = () => {
  const [isIntegrationSuccess, setIsIntegrationSuccess] = useState(null);

  const checkIntegration = () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };
    axios
      .get(`${CONFIG.apiUrl}/user/me`, config)
      .then(res => {
        console.log(res.data);
        if (res.data && res.data.isAlreadyConnected) {
          console.log('Integration success');
          setIsIntegrationSuccess(true);
        } else {
          console.log('Integration failed');
          setIsIntegrationSuccess(false);
        }
      })
      .catch(err => {
        console.log('Integration failed', err);
        setIsIntegrationSuccess(false);
      });
  };

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[30px] pr-[28px] border bg-white shadow-md rounded-xl pl-[33px]'}>
      <div className="flex flex-col items-center text-center">
          <h2 className="text-lg font-bold mb-4">
            2- Vérifiez l'intégration de Leadrs
          </h2>
          <p className="text-gray-700 mb-6">
            Une fois connecté à votre application, envoyez un lead de test avec l'email "integrate@leadrs.co" puis cliquez sur le bouton ci-dessous pour vérifier l'intégration.
          </p>
        </div>
      {isIntegrationSuccess === false && (
        <p className="mt-3 text-base leading-6 text-red-400">
          <b>Erreur:</b> nous n'avons pas encore reçu le lead de test
          'integrate@leadrs.co'.
        </p>
      )}
      {isIntegrationSuccess ? (
        <button
          className="bg-green-600 text-white font-semibold py-2 px-4 rounded-lg"
          onClick={() => (window.location.href = '/')}
        >
          Intégration réussie !
        </button>
      ) : (
        <button
          className="bg-leadrs text-white font-semibold py-2 px-4 rounded-lg"
          onClick={checkIntegration}
        >
         Vérifier l'intégration
        </button>
      )}
    </Card>
  );
};

export default Invite;
