import OfferCard from './components/SellerOfferCard';
import avatar1 from 'assets/img/avatars/avatar1.png';
import avatar2 from 'assets/img/avatars/avatar2.png';
import avatar3 from 'assets/img/avatars/avatar3.png';

import SolarImg from 'assets/offers/solar.png';
import HeatImg from 'assets/offers/heat.jpeg';
import InvestImg from 'assets/offers/invest.jpg';
import formationImg from 'assets/offers/formation.jpg';
import AssuranceImg from 'assets/offers/assurance.jpg';

import { useEffect, useState } from 'react';
import axios from 'axios';
import ModalSeller from './components/Modal';
import { useDisclosure } from '@chakra-ui/hooks';
import { CONFIG } from 'variables/config';
import Card from 'components/card';
import { useNavigate } from 'react-router-dom';

const getImage = (industry: string, subIndustry: string) => {
  if (industry === 'Formation') return formationImg;
  if (industry === 'Assurance') return AssuranceImg;
  if (industry === 'Investissement') return InvestImg;
  if (subIndustry === 'Panneaux solaire') return SolarImg;
  if (subIndustry === 'Pompe à chaleur') return HeatImg;
};

const MyOffer = () => {
  // const [offers, setOffers] = useState([]);
  const [user, setUser] = useState(null);
  const [myOffers, setMyOffers] = useState([]);
  const [isURLLoading, setIsURLLoading] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const nav = useNavigate();

  const initUser = () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };
    axios
      .get(`${CONFIG.apiUrl}/user/me`, config)
      .then(res => {
        setUser(res.data);
        if (res.data && res.data.type !== 'seller') {
          window.location.href = '/admin/my-request';
        }
        console.log(res.data);
      })
      .catch(err => {
        console.log('No user info found', err);
      });
  };

  const initMyOffer = () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };
    axios
      .get(`${CONFIG.apiUrl}/seller-offer/mine`, config)
      .then(res => {
        setMyOffers(res.data);
        console.log(res.data);
      })
      .catch(err => {
        console.log('No user info found', err);
        window.location.href = '/auth/sign-in';
      });
  };

  // const initOffer = () => {
  //   const config = {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: 'Bearer ' + localStorage.getItem('token'),
  //     },
  //   };
  //   axios
  //     .get(`${CONFIG.apiUrl}/seller-offer`, config)
  //     .then(res => {
  //       //setOffers(res.data);
  //       console.log(res.data);
  //     })
  //     .catch(err => {
  //       console.log('No user info found', err);
  //     });
  // };

  const startKYCProcess = () => {
    setIsURLLoading(true);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };
    axios
      .get(`${CONFIG.apiUrl}/stripe/kyc`, config)
      .then(res => {
        console.log(res.data);
        window.location.href = res.data.url;
      })
      .catch(err => {
        console.log('Impossible to prepare KYC process', err);
      });
  };

  useEffect(() => {
    if (
      !localStorage.getItem('token') ||
      localStorage.getItem('token').length < 10
    )
      window.location.href = '/auth/sign-up';
    //initOffer();
    initMyOffer();
    initUser();
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const DiscoverCommandPage = () => {
    return (
      <Card
        extra={
          'w-full pb-[10px] pt-[10px] px-[29px] h-fit shadow shadow-3xl rounded-[20px] border'
        }
      >
        {/* Header */}
        <div className="mt-1">
          <p className="text-xl font-semibold text-navy-700 dark:text-white">
            Mes offres 🎉
          </p>
          <p className="mt-2 text-base text-gray-600">
            Avec Leadrs c'est facile, il suffit de choissir une offre, de
            proposer un prix. Une fois que l'offre est acceptée par l'acheteur,
            vous pouvez commencer à vendre vos leads en automatique.
          </p>
        </div>
      </Card>
    );
  };

  return (
    <div className="h-full w-full rounded-[20px]">
      <ModalSeller
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        selectedOffer={selectedOffer}
        isKYCVerified={user && user.isKYCVerified}
        isValidated={user && user.isValidated}
      />
      {user && user.isKYCVerified === false && (
        <div
          className="relative rounded-[15px] border mb-3 bg-red-100 px-4 py-3 text-yellow-700"
          role="alert"
        >
          <span className="block sm:inline">Vous devez </span>
          <span
            className="block cursor-pointer text-blue-600 underline visited:text-purple-600 hover:text-blue-800 sm:inline"
            onClick={startKYCProcess}
          >
            ajouter vos coordonnées bancaires
          </span>
          <span className="block sm:inline">
            {' '}
            pour pouvoir commencer à vendre. (Utilisez le mail par défaut uniquement)
          </span>
          {isURLLoading && (
            <svg
              aria-hidden="true"
              className="ml-2 inline h-4 w-4 animate-spin fill-yellow-700 text-gray-200 dark:text-gray-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          )}
        </div>
      )}
      <DiscoverCommandPage />
      <div className='flex justify-end'>
      <button
        className="linear mt-4 flex items-center justify-end rounded-xl bg-leadrs px-5 py-2 text-xs font-medium text-white transition duration-200 hover:bg-leadrsLight"
         onClick={() => nav('/admin/seller-offer')}>
          Ajouter une offre
      </button>
      </div>
      <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-4">
        {myOffers.map(offer => {
          return (
            <OfferCard
              key={offer._id}
              bidders={[avatar1, avatar2, avatar3]}
              title={offer.subIndustry}
              author={offer.industry}
              status={offer.status}
              // eslint-disable-next-line eqeqeq
              isArchived={offer.weeklyQuantity == offer.leadsGenerated}
              price={offer.buyerPrice}
              weeklyQuantity={offer.weeklyQuantity}
              image={getImage(offer.industry, offer.subIndustry)}
              onClick={() => {
                nav(`/admin/offer/${offer._id}`);
              }}
            />
          );
        })}
      </div>
      {myOffers.length === 0 && (
        <div className="justify-center mt-5">
          <p className="flex justify-center text-xl text-gray-400">Vous n'avez pas encore d'offre !</p>
          <button
            className="flex mx-auto mt-4 rounded-xl bg-leadrs px-6 py-3 text-md font-medium text-white transition duration-200 hover:bg-leadrsLight"
            onClick={() => nav('/admin/seller-offer')}>
             Créer ma première offre
          </button>
        </div>
      )}
    </div>
  );
};

export default MyOffer;
