import axios from 'axios';
import Card from 'components/card';
import InputField from 'components/fields/InputField';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { CONFIG } from 'variables/config';

const UpdateSellerInvoiceInformation = () => {
    const [user, setUser] = useState(null);
    const [defaultUser, setDefaultUser] = useState(null);
    const nav = useNavigate();

    const initUser = () => {
        const token = localStorage.getItem('token');
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        axios
            .get(`${CONFIG.apiUrl}/user/me`, config)
            .then((res) => {
                const user = res.data;
                setUser(user);
                setDefaultUser(user);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleChange = (e: any) => {
        const { id, value } = e.target;
        setUser({ ...user, [id]: value });
    };

    const updateProfil = () => {
        const token = localStorage.getItem('token');
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        const data = {
            company: user.company,
            address: user.address,
            vatNumber: user.vatNumber,
            siren: user.siren,
        };
        axios
            .put(`${CONFIG.apiUrl}/user`, data, config)
            .then((res) => {
                toast.success('Votre profil a été mis à jour');
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        initUser();
    }, []);

    return (
        <Card extra={'w-full mt-3 px-6 py-6 border-2'}>
            <ToastContainer />
            {/* Header */}
            <div className="w-full px-[8px]">
                <h4 className="text-lg font-bold text-navy-700 dark:text-white">
                    Information de facturation
                </h4>
            </div>
            {/* inputs */}
            <div className="mt-[37px] grid grid-cols-2 gap-2">
                <InputField
                    extra="mb-3"
                    label="Mon entreprise"
                    placeholder="Mon entreprise"
                    defaultValue={user?.company}
                    id="company"
                    type="text"
                    onChange={handleChange}
                />
                <InputField
                    extra="mb-3"
                    label="Adresse"
                    placeholder="17 rue de la paix, 75000 Paris France"
                    defaultValue={user?.address}
                    id="address"
                    type="text"
                    onChange={handleChange}
                />
                <InputField
                    extra="mb-3"
                    label="Numéro de TVA"
                    placeholder="FR12345678901"
                    defaultValue={user?.vatNumber}
                    id="vatNumber"
                    type="text"
                    onChange={handleChange}
                />
                <InputField
                    extra="mb-3"
                    label="Numéro de SIREN"
                    placeholder="123456789"
                    defaultValue={user?.siren}
                    id="siren"
                    type="text"
                    onChange={handleChange}
                />
            </div>
            <div className="mt-1 flex w-full justify-end">
                <button
                    className="linear mt-2 rounded-xl bg-leadrs px-8 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    onClick={() => updateProfil()}
                >
                    Sauvegarder
                </button>
            </div>
        </Card>
    );
};

export default UpdateSellerInvoiceInformation;
