import Card from 'components/card';

const OfferCard = (props: {
  image: string;
  title: string;
  author: string;
  bidders: string[];
  download?: string;
  price: string | number;
  weeklyQuantity?: string | number;
  extra?: string;
  status?: string;
  buttonDisabled?: boolean;
  isKYCVerified?: boolean;
  isValidated?: boolean;
  isArchived?: boolean;
  onClick?: () => void;
}) => {
  const {
    title,
    author,
    price,
    image,
    extra,
	isArchived,
    weeklyQuantity,
    onClick,
    status,
    isKYCVerified,
	isValidated
  } = props;
  return (
    <Card
      extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] min-w-[250px] border-2 shadow-xl bg-white ${extra}`}
    >
      <div className="h-full w-full">
        <div className="relative w-full">
          <img
            src={image}
            className="mb-3 max-h-[130px] w-full rounded-xl 3xl:h-full 3xl:w-full"
            alt=""
          />
          {!image && (
            <div className="flex gap-5 items-center justify-center w-full h-[130px] bg-gray-200 dark:bg-white/10 rounded-xl">
              <p className="text-xl font-bold text-gray-400 dark:text-white">
                {title}
              </p>
              <p className="text-sm font-medium text-gray-400 dark:text-white">
                {author}
              </p>
            </div>
          )}
        </div>

        <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
          <div className="mb-2 w-full">
            <p className="text-lg font-bold text-navy-700 dark:text-white">
              {' '}
              {title}{' '}
            </p>
            <div className='mb-8 w-full flex justify-between'>
              <p className="text-sm font-medium text-gray-600 md:mt-2">
                {author}
              </p>
              <p className="text-sm font-bold text-leadrs dark:text-white md:mt-2">
                {weeklyQuantity} leads
              </p>
            </div>
            {isArchived && (
              <p className="mt-2 font-bold text-green-500">Mission terminée</p>
            )}
          </div>
        </div>

        <div className="">
          {status === 'on_market' && isKYCVerified && isValidated ? (
            <button
              className="flex mx-auto linear rounded-[20px] bg-leadrs px-4 py-2 text-xs lg:text-sm font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
              onClick={onClick}
            >
              Vendre {price}€ HT par leads
            </button>
          ) : (
            <>
              <button
                className="mx-auto linear rounded-[20px] bg-leadrs px-4 py-2 text-sm font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                onClick={onClick}
              >
                Visualiser
              </button>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default OfferCard;
