import axios from 'axios';
import InputField from 'components/fields/InputField';
import Default from 'layouts/auth/types/Default';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { CONFIG } from 'variables/config';

function ForgotPasswordDefault() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const resetPassword = () => {
        const token = localStorage.getItem('token');
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        axios
            .post(`${CONFIG.apiUrl}/auth/forgot-password`, { email }, config)
            .then((res: any) => {
                toast.success(
                    'Un lien de réinitialisation de mot de passe vous a été envoyé par mail'
                );
                setMessage(
                    'Un lien de réinitialisation de mot de passe vous a été envoyé par mail'
                );
            })
            .catch((err: any) => {
                toast.success(
                    'Un lien de réinitialisation de mot de passe vous a été envoyé par mail'
                );
                setMessage(
                    'Un lien de réinitialisation de mot de passe vous a été envoyé par mail'
                );
                console.log(err);
            });
    };

    return (
        <>
            <Default
                maincard={
                    <div className="mb-16 flex h-full w-full items-center justify-center md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
                        {/* Sign in section */}
                        <div className="mt-[16vh] flex flex-col rounded-[20px] pb-16 pt-12 md:pr-6 lg:max-w-[90%] lg:pr-0 xl:max-w-[99%] xl:pr-24">
                            <h3 className="text-xl font-medium text-navy-700 dark:text-white lg:text-2xl">
                                Mot de passe oublié ?
                            </h3>
                            <p className="mb-8 ml-1 mt-2 text-base text-gray-600">
                                Aucun soucis, entrez votre adresse mail pour
                                recevoir un lien de réinitialisation de votre
                                mot de passe.
                            </p>
                            {/* Email */}
                            <InputField
                                variant="auth"
                                label="Email"
                                placeholder="my-email@leadrs.com"
                                id="email"
                                type="text"
                                onChange={(e: any) => setEmail(e.target.value)}
                            />
                            {message && (
                                <p className="mt-1 text-sm text-leadrs">
                                    {message}
                                </p>
                            )}
                            {/* button */}
                            <button
                                className="linear mt-4 w-full rounded-xl bg-leadrs py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600"
                                onClick={resetPassword}
                            >
                                Recevoir un lien de réinitialisation par mail
                            </button>
                        </div>
                    </div>
                }
            />
        </>
    );
}

export default ForgotPasswordDefault;
