import { MdAnalytics, MdHome, MdKey, MdLock, MdLockOutline, MdOutlineShoppingBag } from 'react-icons/md';

// Admin Imports
import Dashboard from 'views/admin/main/buyer/sellerOffer';

// Auth Imports
import LockCentered from 'views/auth/lock/LockCenter';
import LockDefault from 'views/auth/lock/LockDefault';
import SignInDefault from 'views/auth/signIn/SignInDefault';
import SignUpDefault from 'views/auth/signUp/SignUpDefault';
import VerificationCentered from 'views/auth/verification/VerificationCenter';
import VerificationDefault from 'views/auth/verification/VerificationDefault';
import SignUpSeller from 'views/auth/signUp/SignUpSeller';
import ProfileSettings from 'views/admin/main/profile/settings';
import EcommerceNewProduct from 'views/admin/main/ecommerce/newProduct';
import StripeBilling from 'views/admin/main/account/stripe';
import MyOffer from 'views/admin/main/buyer/myOffer';
import { FaRobot } from 'react-icons/fa';
import { IoDiscOutline } from 'react-icons/io5';
import MyLeads from 'views/admin/main/buyer/leads';
import IA from 'views/admin/main/buyer/ia';
import Integrate from 'views/admin/main/buyer/integrate';
import ReportingDashboard from 'views/admin/main/buyer/dashboard';
import { AiOutlineFileAdd } from 'react-icons/ai';
import SellerIntegration from 'views/admin/main/ecommerce/referrals';

const routesBuyer = [
  {
    name: 'Nos offres',
    layout: '/admin',
    path: '/dashboard',
    icon: <AiOutlineFileAdd className="text-inherit h-5 w-5" />,
    component: <Dashboard />,
    menu: true,
  },
  {
    name: 'Mes commandes',
    layout: '/admin',
    path: '/my-request',
    icon: <MdOutlineShoppingBag className="text-inherit h-6 w-6" />,
    component: <MyOffer />,
    menu: true,
  },
  {
    name: 'Profile Settings',
    layout: '/admin',
    path: '/settings',
    exact: false,
    icon: <MdHome className="text-inherit h-6 w-6" />,
    component: <ProfileSettings />,
    menu: false,
  },
  {
    name: 'Mes leads',
    layout: '/admin',
    path: '/leads',
    icon: <IoDiscOutline className="text-inherit h-6 w-6" />,
    component: <MyLeads />,
    menu: true,
  },
  {
    name: 'IA',
    layout: '/admin',
    path: '/IA',
    icon: <FaRobot className="text-inherit h-6 w-6" />,
    component: <IA />,
    menu: true,
  },
  {
    name: 'Integration',
    layout: '/admin',
    path: '/integrates',
    icon: <MdKey className="text-inherit h-6 w-6" />,
    component: <Integrate />,
    menu: true,
  },
  {
    name: 'Analytics & Reporting',
    layout: '/admin',
    path: '/dash-report',
    icon: <MdAnalytics className="text-inherit h-6 w-6" />,
    component: <ReportingDashboard />,
    menu: true,
  },
  // {
  //   name: 'Paiement',
  //   layout: '/admin',
  //   path: '/paiement',
  //   icon: <BiMoney className='text-inherit h-6 w-6' />,
  //   availble: false,
  //   menu: true,
  // },
  {
    name: 'Attente de validation',
    layout: '/admin',
    path: '/seller/integrate',
    icon: <MdLockOutline className="text-inherit h-6 w-6" />,
    component: <SellerIntegration />,
  },
  {
    name: 'Créer une offre',
    layout: '/admin',
    path: '/new-offer',
    icon: <MdLockOutline className="text-inherit h-6 w-6" />,
    component: <EcommerceNewProduct />,
  },
  {
    name: 'Créer une offre',
    layout: '/admin',
    path: '/create-order',
    icon: <MdLockOutline className="text-inherit h-6 w-6" />,
    component: <EcommerceNewProduct />,
  },
  {
    name: 'Abonnements',
    layout: '/admin',
    path: '/subscribe',
    exact: false,
    icon: <MdLockOutline className="text-inherit h-6 w-6" />,
    component: <StripeBilling />,
  },
  // --- Authentication ---
  {
    name: 'Authentication',
    path: '/auth',
    icon: <MdLock className="text-inherit h-5 w-5" />,
    collapse: true,
    menu: false,
    items: [
      // --- Sign In ---
      {
        name: 'Sign In',
        path: '/sign-in',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/sign-in',
            component: <SignInDefault />,
          },
        ],
      },
      // --- Sign Up ---
      {
        name: 'Sign Up',
        path: '/sign-up',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/sign-up',
            component: <SignUpDefault />,
          },
        ],
      },
      {
        name: 'Sign Up',
        path: '/seller/sign-up',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/seller/sign-up',
            component: <SignUpSeller />,
          },
        ],
      },
      // --- Verification ---
      {
        name: 'Verification',
        path: '/verification',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/verification/default',
            component: <VerificationDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/verification/centered',
            component: <VerificationCentered />,
          },
        ],
      },
      // --- Lock ---
      {
        name: 'Lock',
        path: '/lock',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/lock/default',
            component: <LockDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/lock/centered',
            component: <LockCentered />,
          },
        ],
      },
    ],
  },
];
export default routesBuyer;
