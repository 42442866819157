import SellerIntegration from "../../ecommerce/referrals";

const Integrate = () => {
  return (
    <>
      <SellerIntegration />
    </>
  );
};

export default Integrate;