import axios from 'axios';
import InputField from 'components/fields/InputField';
import Default from 'layouts/auth/types/Default';
import { useState } from 'react';
import { CONFIG } from 'variables/config';

function ResetPassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');

    const resetPassword = () => {
        if (password !== confirmPassword) {
            setMessage('Les mots de passe ne correspondent pas');
            return;
        }

        const params = new URLSearchParams(window.location.search);
        const resetToken = params.get('t');
        const token = localStorage.getItem('token');
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        axios
            .post(
                `${CONFIG.apiUrl}/auth/reset-password`,
                { token: resetToken, password },
                config
            )
            .then((res: any) => {
                window.location.href = '/auth/sign-in';
            })
            .catch((err: any) => {
                setMessage(err.response.data.error);
                console.log(err);
            });
    };

    return (
        <>
            <Default
                maincard={
                    <div className="mb-16 flex h-full w-full items-center justify-center md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
                        {/* Sign in section */}
                        <div className="mt-[16vh] flex flex-col rounded-[20px] pb-16 pt-12 md:pr-6 lg:pr-0 xl:pr-24">
                            <h3 className="text-xl font-medium text-navy-700 dark:text-white lg:text-2xl">
                                Modifier mon mot de passe
                            </h3>
                            <p className="mb-8 ml-1 mt-2 text-base text-gray-600">
                                Entrez votre nouveau mot de passe.
                            </p>
                            {/* Email */}
                            <InputField
                                variant="auth"
                                label="Mot de passe*"
                                placeholder="********"
                                id="password"
                                type="password"
                                onChange={(e: any) =>
                                    setPassword(e.target.value)
                                }
                            />
                            <div className="mt-4"></div>
                            <InputField
                                variant="auth"
                                label="Confirmation du mot de passe*"
                                placeholder="********"
                                id="confirm_password"
                                type="password"
                                onChange={(e: any) =>
                                    setConfirmPassword(e.target.value)
                                }
                            />
                            {message && (
                                <p className="mt-1 text-sm text-leadrs">
                                    {message}
                                </p>
                            )}
                            {/* button */}
                            <button
                                className="linear mt-4 w-full rounded-xl bg-leadrs py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600"
                                onClick={resetPassword}
                            >
                                Changer mon mot de passe
                            </button>
                        </div>
                    </div>
                }
            />
        </>
    );
}

export default ResetPassword;
